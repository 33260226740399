<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <h1>{{fullname}}</h1>
      </v-col>
    </v-row>
    <v-row dense v-if="userStatus === -1">
      <v-col cols="12">
        <v-alert type="error" outlined text :value="true" prominent>
          <v-row align="center">
            <v-col class="grow">
              <strong>You have not verified your account.</strong>
            </v-col>
            <v-col class="shrink">
              <v-btn
                :to="{name: 'verify-me'}"
                color="error white--text"
              >Verify</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row dense v-if="userStatus > 0">
      <v-col cols="12">
        <profile-check></profile-check>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color2 color2Text--text">
            <v-toolbar-title>Player Profile{{user.profiles.length > 1 ? 's' : ''}}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="player in user.profiles" :key="player.profile.id">
                  <profile-card
                    :player="player.profile"
                    size="100%"
                  ></profile-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-if="user.profiles.length === 0">
                  <v-card class="fill-height d-flex align-center text-center">
                    <v-card-text class="title">
                      You do not have any player profiles
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                  <BidStream :size="`300x250${$vuetify.breakpoint.xsOnly ? 'M' : ''}`"></BidStream>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <contact-info :altUser="altUser"></contact-info>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <sign-in-methods></sign-in-methods>
      </v-col>
    </v-row>
    <v-snackbar
      bottom
      vertical
      :timeout="5000"
      v-model="snackbar"
      :color="snackColor"
    >
      {{snackbarMessage}}
      <v-btn text color="white" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

  </v-container>

</template>

<script>
import User from '@/classes/User.js'
const ContactInfo = () => import('@/components/User/ContactInfo.vue')
const SignInMethods = () => import('@/components/User/SignInMethods.vue')
const ProfileCard = () => import('@/components/Player/ProfileCard.vue')
const ProfileCheck = () => import('@/components/User/ProfileCheck')

export default {
  data () {
    return {
      snackbar: false,
      snackbarMessage: null,
      snackColor: 'success',
      altUser: null
    }
  },
  computed: {
    user () {
      return this.altUser || this.$store.getters.user
    },
    userStatus () {
      // return -1
      if (!this.user) return 0 // 'No User'
      if (!this.user.hasVerifiedContact) return -1 // 'Need Verification'
      return 13
    },
    fullname () {
      return this.user && [this.user.firstName, this.user.lastName].join(' ')
    }
  },
  components: {
    ContactInfo,
    SignInMethods,
    ProfileCard,
    ProfileCheck
  },
  methods: {
    loadUser (uid) {
      this.$VBL.user.getMe(uid)
        .then(r => {
          // this.altUser = r.data
          this.altUser = new User(r.data)
        })
    }
  },
  created () {
    const uid = this.$route.query.uid
    uid && this.loadUser(uid)
    const msg = this.$route.query.message
    if (msg) {
      this.$router.replace(this.$route.path)
      switch (msg) {
        case 'emailConfirmed':
          this.snackbarMessage = 'Your email has been verified'
          this.snackColor = 'success'
          break
        case 'emailConfirmFailed':
          this.snackbarMessage = 'Invalid email token'
          this.snackColor = 'error'
          break
      }
      this.snackbar = true
    }
  }
}
</script>

<style scoped>
.bw {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
</style>
